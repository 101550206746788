import * as analytics from '@icepanel/app-analytics'

import { landscapeId } from '@/modules/landscape/helpers/analytics-group'
import { organizationId } from '@/modules/organization/helpers/analytics-group'

import { supportMessageLength, supportMessageSubject, supportMessageType } from './analytics-properties'

/**
 * Dialogs
 */

export const supportDialog = new analytics.Event({
  displayName: 'Support dialog',
  name: 'support_dialog',
  tags: [analytics.Tags.Dialog]
}, {
  landscapeId,
  organizationId
})

/**
 * Events
 */

export const supportMessage = new analytics.Event({
  displayName: 'Support message',
  name: 'support_message'
}, {
  landscapeId,
  organizationId,
  supportMessageLength,
  supportMessageSubject,
  supportMessageType
})
