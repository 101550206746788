import { IconDefinition, Library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueConstructor from 'vue'
import type Vuetify from 'vuetify/lib'
import { Store } from 'vuex'

import SupportMenu from './components/menu.vue'
import icons from './helpers/icons'
import { SupportModule } from './store'

export const install = ({ vuetify, store, Vue, library }: { vuetify: Vuetify, store: Store<any>, Vue: typeof VueConstructor, library: Library }) => {
  icons.forEach((definition) => {
    library.add(definition as IconDefinition)

    vuetify.framework.icons.values[`${definition.prefix}-${definition.iconName}`] = {
      component: FontAwesomeIcon,
      props: {
        icon: [definition.prefix, definition.iconName]
      }
    }
  })

  Vue.component('SupportMenu', SupportMenu)

  store.registerModule(SupportModule.namespace, SupportModule)
}
