
import { FormController, validationRules } from '@icepanel/app-form'
import { SupportMessageAttachment, SupportMessageType } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Ref, Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import Menu from '@/components/menu.vue'
import { AlertModule } from '@/modules/alert/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { OrganizationModule } from '@/modules/organization/store'
import { ShareModule } from '@/modules/share/store'
import { UserModule } from '@/modules/user/store'
import { VersionModule } from '@/modules/version/store'

import * as analytics from '../helpers/analytics'
import { SupportModule } from '../store'

@Component({
  components: {
    Menu
  },
  name: 'SupportMenu'
})
export default class extends Vue {
  alertModule = getModule(AlertModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  supportModule = getModule(SupportModule, this.$store)
  userModule = getModule(UserModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  screenWidth = 0
  screenHeight = 0

  fileDragover = false

  @Ref() readonly menuRef!: Menu

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion?.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)
  }

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.currentLandscape?.organizationId)
  }

  get isOpen () {
    return this.supportModule.open
  }

  get attachmentTypes () {
    return ['image/*']
  }

  get types (): { text: string, value: SupportMessageType }[] {
    return [
      {
        text: 'Sales',
        value: 'sales'
      },
      {
        text: 'Support',
        value: 'support'
      },
      {
        text: 'Other',
        value: 'general'
      }
    ]
  }

  get feelings (): { text: string, value: string }[] {
    return [
      {
        text: '🤩 Excited',
        value: 'Excited 🤩'
      },
      {
        text: '😀 Happy',
        value: 'Happy 😀'
      },
      {
        text: '😐 Neutral',
        value: 'Neutral 😐'
      },
      {
        text: '😢 Sad',
        value: 'Sad 😢'
      },
      {
        text: '😡 Angry',
        value: 'Angry 😡'
      },
      {
        text: '😎 Cool',
        value: 'Cool 😎'
      }
    ]
  }

  get user () {
    return this.userModule.user
  }

  formController = new FormController({
    initialModel: {
      attachments: [] as SupportMessageAttachment[],
      email: '',
      feeling: '',
      message: '',
      subject: '',
      type: '' as Extract<SupportMessageType, 'sales' | 'support' | 'general'>
    },
    validationRules: {
      email: validationRules.email,
      message: validationRules.exists,
      subject: validationRules.exists,
      type: validationRules.exists
    }
  })

  @Watch('isOpen')
  onIsOpenChanged (isOpen: boolean) {
    if (isOpen) {
      this.menuRef?.open()
    } else {
      this.menuRef?.close()
    }
  }

  mounted () {
    this.screenHeight = screen.height
    this.screenWidth = screen.width

    if (this.isOpen) {
      this.menuRef?.open()
    } else {
      this.menuRef?.close()
    }
  }

  created () {
    this.formController.submitHandler = async model => {
      const email = this.userModule.user?.email || model.email

      if (!email || !model.type) {
        return
      }

      await this.supportModule.supportMessageCreate({
        body: {
          attachments: model.attachments,
          feeling: model.feeling,
          message: model.message,
          subject: model.subject,
          type: model.type
        },
        email,
        landscapeId: this.currentLandscape?.id,
        name: this.userModule.user?.name,
        organizationId: this.currentOrganization?.id,
        url: window.location.href,
        userId: this.userModule.user?.id
      })

      analytics.supportMessage.track(this, {
        landscapeId: this.currentLandscape ? [this.currentLandscape.id] : [],
        organizationId: this.currentOrganization ? [this.currentOrganization.id] : [],
        supportMessageLength: model.message.length,
        supportMessageSubject: model.subject,
        supportMessageType: model.type
      })
    }
    this.formController.on('error', err => {
      this.alertModule.pushAlert({
        color: 'error',
        message: err
      })
    })
  }

  open () {
    this.reset()
  }

  opened () {
    analytics.supportDialog.track(this, {
      landscapeId: this.currentLandscape ? [this.currentLandscape.id] : [],
      organizationId: this.currentOrganization ? [this.currentOrganization.id] : []
    })
  }

  close () {
    this.supportModule.setOpen(false)
  }

  resize () {
    this.screenHeight = screen.height
    this.screenWidth = screen.width
  }

  reset () {
    this.formController.resetModel()
    this.formController.resetValidation()
    this.formController.resetStatus()
  }

  selectAttachments () {
    const input = document.createElement('input')
    input.type = 'file'
    input.multiple = true
    input.accept = this.attachmentTypes.join(',')

    input.onchange = (e: Event & { target: EventTarget | HTMLInputElement | null }) => {
      if (e.target && 'files' in e.target && e.target.files?.length) {
        this.handleFiles(e.target.files)
      }
    }

    input.click()
  }

  dropAttachments (e: DragEvent) {
    this.fileDragover = false

    const files = e.dataTransfer?.files
    if (files) {
      this.handleFiles(files)
    }
  }

  removeAttachment (filename: string) {
    this.formController.model.attachments = this.formController.model.attachments.filter(o => o.filename !== filename)
  }

  handleFiles (fileList: FileList) {
    // getting a hold of the file reference
    const files = [
      fileList.item(0),
      fileList.item(1),
      fileList.item(2),
      fileList.item(3)
    ].filter((o): o is File => !!o)

    // read the data from each file
    files
      .slice(0, 4 - this.formController.model.attachments.length)
      .forEach(o => {
        const reader = new FileReader()
        reader.readAsDataURL(o)

        reader.onload = readerEvent => {
          const content = readerEvent.target?.result
          if (typeof content === 'string') {
            const [, data] = content.split(',')
            this.formController.model.attachments.push({
              data,
              filename: o.name,
              size: o.size,
              type: o.type
            })
          }
        }
      })
  }
}
