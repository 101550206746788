import { SupportMessageRequired, SupportService } from '@icepanel/platform-api-client'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'

export interface ISupportModule {
  open: boolean
}

const name = 'support'

@Module({
  name,
  namespaced: true
})
export class SupportModule extends VuexModule {
  static namespace = name

  open = false

  @Mutation
  setOpen (open: boolean) {
    this.open = open
  }

  @Action({ rawError: true })
  supportMessageCreate (message: SupportMessageRequired) {
    return SupportService.supportMessageCreate(message)
  }
}
