import * as analytics from '@icepanel/app-analytics'
import { SupportMessageType } from '@icepanel/platform-api-client'

export const supportMessageType: analytics.Property<SupportMessageType> = {
  displayName: 'Support message type',
  name: 'support_message_type',
  required: true,
  type: 'string'
}
export const supportMessageSubject: analytics.Property<string> = {
  displayName: 'Support message subject',
  name: 'support_message_subject',
  required: true,
  type: 'string'
}
export const supportMessageLength: analytics.Property<number> = {
  displayName: 'Support message length',
  name: 'support_message_length',
  required: true,
  type: 'number'
}
